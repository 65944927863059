
let DataPage = {
    "operationCenter"  : {},
    "unimil"           : {},
    "partsAndServices" : {},
    "upgrades"         : {}
};

let NotifyDataPage = {
    "operationCenter"  : [],
    "unimil"           : [],
    "partsAndServices" : [],
    "upgrades"         : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["operationCenter"].forEach(element => {
        element(value['operationCenter']);
    });
    NotifyDataPage["unimil"].forEach(element => {
        element(value['unimil']);
    });
    NotifyDataPage["partsAndServices"].forEach(element => {
        element(value['partsAndServices']);
    });
    NotifyDataPage["upgrades"].forEach(element => {
        element(value['upgrades']);
    });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
