import { useState, useEffect } from "react";

import './MesaUnimil.css';

import Axios from 'axios';

import { GetDataPage, SetListData } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import MesaUnimil_Home from "./Home";
import MesaUnimil_WhatIsIt from "./WhatIsIt";
import MesaUnimil_WhatAreTheBenefits from "./WhatAreTheBenefits";
import MesaUnimil_WhereToBuy from "./WhereToBuy";

import MesaUnimil_UnimilByJohnDeere from "./UnimilByJohnDeere";
import MesaUnimil_UnimilCana from "./UnimilCana";
import { RegisterDataPage } from "interface/Data";

export default function MesaUnimil(props){

    const [ showData, setShowData ] = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('unimil'));
    const [ page, setPage ]         = useState('index');

    useEffect(()=>{
        RegisterDataPage('unimil', setDataPage)
        CountShowData();
        TypePage();
    }, []); 

    useEffect(()=>{
        CountShowData();
        TypePage();
    }, [page]);
    
    function CountShowData(){
        let count = 0;
        const countData = setInterval(() => {
            if(count == 1){
                setShowData(true); 
                clearInterval(countData);
            }
            count++; 
        }, 300);
    }

    function TypePage(){
        switch (page) {
            case 'index':
                return <MesaUnimil_Home ClickPage={ ClickPage } dataPage={ dataPage } />;
            
            case 'WhatIsIt':
                return <MesaUnimil_WhatIsIt ClickPage={ ClickPage } />;

            case 'WhatAreTheBenefits':
                return <MesaUnimil_WhatAreTheBenefits ClickPage={ ClickPage } />;
            
            case 'WhereToBuy':
                return <MesaUnimil_WhereToBuy ClickPage={ ClickPage } />;

            case 'UnimilByJohnDeere':
                return <MesaUnimil_UnimilByJohnDeere ClickPage={ ClickPage } />;

            case 'UnimilCana':
                return <MesaUnimil_UnimilCana ClickPage={ ClickPage } />;
        }
    }

    function ClickPage(value){
        setPage(value);
    }

    return(
        <div className="MesaUnimil">
            <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } />
            <div className={ showData == true ? "div_data show_data" : "div_data" }>
                {
                    TypePage()
                }
            </div>
            <div className="div_bg">
                <img alt="img" src="./assets/texture.png" className="texture" />
            </div>
        </div>
    )
}