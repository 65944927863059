import { useState, useEffect } from "react";

import './Home.css';
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";
import { SvgArrow } from "components/Svg";

export default function MesaUnimil_Home(props){

    const [ showData, setShowData ] = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('unimil'));
    const [ page, setPage ]         = useState('index');

    const [ title_1, setTitle_1 ]   = useState('');
    const [ button_1, setButton_1 ] = useState('');
    const [ button_2, setButton_2 ] = useState('');
    const [ button_3, setButton_3 ] = useState('');

    const [ title_2, setTitle_2 ]       = useState('');
    const [ catTitle_1, setCatTitle_1 ] = useState('');
    const [ catImg_1, setCatImg_1 ]     = useState('');
    const [ catTitle_2, setCatTitle_2 ] = useState('');
    const [ catImg_2, setCatImg_2 ]     = useState('');

    useEffect(()=>{
        RegisterDataPage('unimil', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('unimil'));

        InitialData('title_1');
        InitialData('button_1');
        InitialData('button_2');
        InitialData('button_3');

        InitialData('title_2');
        InitialData('machine_1');
        InitialData('img_1');
        InitialData('machine_2');
        InitialData('img_2');
    }, [dataPage]);

    function InitialData(type){
        setTimeout(() => {
            switch (type) {
                case 'title_1':
                        setTitle_1(GetDataPage('unimil').about['title']);
                    break;

                case 'button_1':
                        setButton_1(GetDataPage('unimil').about['button_1']);
                    break;
                    
                case 'button_2':
                        setButton_2(GetDataPage('unimil').about['button_2']);
                    break;
                    
                case 'button_3':
                        setButton_3(GetDataPage('unimil').about['button_3']);
                    break;
                    
                case 'title_2':
                        setTitle_2(GetDataPage('unimil').catalog['title']);
                    break;
                    
                case 'machine_1':
                        setCatTitle_1(GetDataPage('unimil').catalog['machine_1']);
                    break;
                    
                case 'img_1':
                        setCatImg_1(GetDataPage('unimil').catalog['img_1']);
                    break;
                    
                case 'machine_2':
                        setCatTitle_2(GetDataPage('unimil').catalog['machine_2']);
                    break;
                    
                case 'img_2':
                        setCatImg_2(GetDataPage('unimil').catalog['img_2']);
                    break;
            }
        }, 1000); 
    }

    return(
        <div className="MesaUnimil_Home">
            <div className="div_show_data">
                <div className="bg_blue">
                    <div className="div_topic">
                        <img alt="img" src="./assets/Unimil/logo.png" className="logotipo" />
                    </div>
                    <div className="show_data_contents">

                        <div className="list_buttons">
                            <div className="btn_about" onClick={ ()=>{ props.ClickPage('WhatIsIt') } }>
                                { button_1 }
                            </div>
                            <div className="btn_about" onClick={ ()=>{ props.ClickPage('WhatAreTheBenefits') } }>
                                { button_2 }
                            </div>
                            <div className="btn_about" onClick={ ()=>{ props.ClickPage('WhereToBuy') } }>
                                { button_3 }
                            </div>
                        </div>
                      
                        <div className="machine" onClick={ ()=>{ props.ClickPage('UnimilByJohnDeere') } }>
                            <div className="div_img">
                                <img alt="machine_1" src="./assets/Unimil/machine_1.jpg" className="img_" />
                            </div>
                            <div className="div_data_machine">
                                <div className="title_machine">{ catTitle_1 }</div>
                                {/* 
                                <div className="div_icon">
                                    <div className="">Ver catálogo</div>
                                    <div className="">
                                        <SvgArrow className="icons_arrow" color="#FFDD00" />
                                    </div>
                                </div> 
                                */}
                            </div>
                        </div>

                    </div>
                    <div className="div_cursor_pointer">
                        <img alt="img" src="./assets/cursor_pointer.png" className="cursor" />
                    </div>
                </div>
                <div className="bg_white">
                    <div className="div_topic">
                        <img alt="img" src="./assets/Unimil/logo_blue.png" className="logotipo" />
                    </div>
                    <div className="show_data_contents">
                        
                        <div className="machine" onClick={ ()=>{ props.ClickPage('UnimilCana') } }>
                            <div className="div_img">
                                <img alt="machine_2" src="./assets/Unimil/machine_2.jpg" className="img_" />
                            </div>
                            <div className="div_data_machine">
                                <div className="title_machine">{ catTitle_2 }</div>
                                {/* 
                                <div className="div_icon">
                                    <div className="">Ver catálogo</div>
                                    <div className="">
                                        <SvgArrow className="icons_arrow" color="#FFDD00" />
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}