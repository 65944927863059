import Cookies from 'universal-cookie';

const cookies = new Cookies();

let ListPag = {
    "idioma"      : "pt_br",
    "currentPage" : 'partsandservices',
};

let NotifyListPag = {
    "currentPage" : [],
    "idioma"      : []
}

export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = [];
    }
    NotifyListPag[key].push(value);
}
