import { useState, useEffect } from "react";

import './UnimilCana.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SvgArrowFile, SvgClose } from "components/Svg";

export default function MesaUnimil_UnimilCana(props){
    
    const [ dataPage, setDataPage ] = useState(GetDataPage('unimil'));
    const [ page, setPage ]         = useState('index');

    const [ imgExample, setImgExample ]   = useState('');
    const [ qrCode, setQrCode ]           = useState('');
    const [ buttonsList, setButtonsList ] = useState([]);
    
    const [ openFile, setOpenFile ]     = useState('');
    const [ statusFile, setStatusFile ] = useState(false);

    useEffect(()=>{
        RegisterDataPage('unimil', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('unimil'));

        InitialData('buttonsList');
    }, [dataPage]);

    function InitialData(type){
        setTimeout(() => {
            switch (type) {
                case "buttonsList":
                        setButtonsList(GetDataPage('unimil').catalog['list_2']);
                    break;
            }
        }, 1000); 
    }

    function CurrentData(){
        switch (page) {
            case 'index':
                return(
                    <div className="div_data">
                        <div className="div_topic">
                            <div className="div_home" onClick={ ()=>{ props.ClickPage('index'); } }>
                                <img alt="img" src="./assets/home.png" className="img_home" />
                            </div>
                            <div className="div_logo">
                                <img alt="img" src="./assets/Unimil/logo_blue_1.png" className="logotipo" />
                            </div>
                            <div className="div_img_example">
                                <img alt="img" src="./assets/Unimil/catalog.png" className="img_example" />
                            </div>
                            <div className="div_qrcode">
                                <div className="">
                                    Baixe o catálogo completo
                                </div>
                                <img alt="img" src={ dataPage.catalog.qrCode_2 } className="img_qrcode" />
                            </div>
                        </div>
                        <div className="contents">
                            <div className="list_buttons">
                                {
                                    buttonsList.map((key, index)=>{
                                        return(
                                            <div className="show_buttons" key={ index } onClick={ ()=>{ setPage(key.id) } }>
                                                { key.title }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="div_bg_color" />
                        <div className="div_cursor_pointer">
                            <img alt="img" src="./assets/cursor_pointer.png" className="cursor" />
                        </div>
                    </div>
                )
            
            default:
                const newData = dataPage['catalog'].list_2.find(item => item.id == page);
                return(
                    <div className="div_data">
                        <div className="show_topic">
                            <div className="div_home" onClick={ ()=>{ setPage('index'); } }>
                                <img alt="img" src="./assets/home.png" className="img_home" />
                            </div>
                            <div className="div_name_page">
                                <div className="subtitle_page">Unimil Soluções em Colhedoras</div>
                                <div className="name_page">{ newData.title }</div>
                            </div>
                            <div className="div_logotipo">
                                <img alt="img" src="./assets/Unimil/logo_blue_1.png" className="logotipo" />
                            </div>
                        </div>
                        <div className="show_contents">
                            {
                                newData.text == '<p><br></p>' || newData.text == '' ? null : 
                                <div className="show_text" dangerouslySetInnerHTML={ { __html: newData.text ? newData.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            }
                            <div className="show_file">
                                <div className={ newData.text == '<p><br></p>' || newData.text == '' ? "div_show_file div_show_file_screen" : "div_show_file" }>
                                    {
                                        newData.list.map((key, index)=>{
                                            return(
                                                <div className="show_img" key={ index } onClick={ ()=>{ setStatusFile(true); setOpenFile(index) } }>
                                                    <div className="subtitle">
                                                        { key.reference }
                                                    </div>
                                                    <div className="search" style={ { top: key.reference !='' ? "40px" : "20px", width: "24px", height: "24px" } }>
                                                        <img alt="search" src="./assets/search.png" className="icon_search" />
                                                    </div> 
                                                    <img alt="img" src={ key.img } className="show_photo" />
                                                    <div className="title">
                                                        { key.code }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            statusFile == false ? null :
                            <div className="div_parts">
                                <div className="list_div_parts">
                                    <div className="close_modal" onClick={ ()=>{ setStatusFile(false); setOpenFile('') } }>
                                        <SvgClose className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        (openFile - 1) >= 0 ? 
                                        <div className="prev_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenFile(openFile - 1) } }>
                                            <SvgArrowFile className="arrow_file" color="#FFFFFF" />
                                        </div> : null
                                    }
                                    {
                                        (openFile + 1) < newData.list.length ?
                                        <div className="next_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenFile(openFile + 1) } }>
                                            <SvgArrowFile className="arrow_file next_icon" color="#FFFFFF" />
                                        </div> : null
                                    }
                                    <div className="name_reference">
                                        { newData.list[openFile].reference }
                                    </div>
                                    <div className="name_code">
                                        { newData.list[openFile].code }
                                    </div>
                                    <div className="show_details">
                                        <div className="mach_div_img">
                                            <img alt="img" src={ newData.list[openFile].img } className="img_div_parts" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )
        }
    }
    
    return(
        <div className="MesaUnimil_UnimilCana">
            { CurrentData() }
        </div>
    )
}