import { useState, useEffect } from "react";

import './MesaUpgrades.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgArrow, SvgArrowFile, SvgClose, SvgHome } from "components/Svg";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function MesaUpgrades(props){

    const [ showData, setShowData ] = useState(false);
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('upgrades'));
    const [ page, setPage ]         = useState('index');
    const [ namePage, setNamePage ] = useState('');

    const [ next, setNext ] = useState('');
    const [ prev, setPrev ] = useState('');

    const [ status, setStatus ] = useState(false);

    const [ openIndex, setOpenIndex ]   = useState('');
    const [ openFile, setOpenFile ]     = useState('');
    const [ statusFile, setStatusFile ] = useState(false);

    let countLeft  = 0;
    let countRight = 0;
    let countImg   = 0;

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('upgrades', setDataPage);
        TypePage();
    }, []); 

    useEffect(()=>{
        TypePage();
    }, [page]);

    function TypePage(){
        switch (page) {
            case 'index':
                return (                    
                    <div className="div_data">
                        <div className="div_topic name_home">
                            <div className="title_">
                                <img alt="name" src="./assets/name_upgrades.png" className="svg_name" />
                            </div>
                        </div>
                        
                        <div className="div_content">
                            <div className="circle_rotate">
                                <div className="div_click_button">
                                    <div className="btn_1" onClick={ ()=>{ setPage('planning'); } } />
                                    <div className="btn_2" onClick={ ()=>{ setPage('plantio'); } } />
                                    <div className="btn_3" onClick={ ()=>{ setPage('colheita'); } } />
                                    <div className="btn_4" onClick={ ()=>{ setPage('tratos_culturais'); } } />
                                    <img alt="img" src={ "./assets/Upgrades/btn_" + idioma + ".png" } className="" />
                                </div>
                            </div>
                        </div>

                        <div className="div_example">
                            <img alt="img" src={ "./assets/cursor_" + idioma + ".png" } className="icone_button" />
                        </div>
                    </div>
                );

            default:
                return ListDataClick();
        }
    }

    function ListDataClick(){
        const showDataPage      = dataPage[page];        
        const countButtonsLeft  = showDataPage.button.filter(item =>item.position === 'left');
        const countButtonsRight = showDataPage.button.filter(item =>item.position === 'right');
        
        let showData = '';
        if(namePage != ''){
            showData = dataPage[page].button.find(item => item.name == namePage); 
        }   

        return(
            <div className="div_data">
                <div className="div_topic topic_details">
                    <div className="return" onClick={ ()=>{ setPage('index'); setNamePage('') } }>
                        <SvgHome className="icon_home" color="#FFDD00" />
                    </div>
                    <div className="name_current_page">
                        <div className="show_name">/</div>
                        <div className="" onClick={ ()=>{ setPage('index'); setNamePage('') } }>Upgrades</div>
                        <div className="show_name">/</div>
                        <div className="" onClick={ ()=>{ setPage(page); setNamePage('') } }>{ showDataPage.name }</div>
                        {
                            namePage == '' ? null : 
                            <>
                                <div className="show_name">/</div>
                                <div className="" dangerouslySetInnerHTML={ { __html: namePage } } />
                            </>
                        }
                    </div>
                </div>

                <div className="div_content">
                    {
                        namePage == '' ?
                        <>                            
                            <div className="show_title" dangerouslySetInnerHTML={ { __html: showDataPage.name ? showDataPage.name.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            <div className="show_buttons">

                                <div className={ countButtonsLeft.length > 5 ? "div_buttons alt_gap" : "div_buttons" }>
                                    {
                                        showDataPage.button.map((key, index)=>{
                                            if(key.position == 'left'){
                                                countLeft = countLeft + 1;
                                                return(
                                                    <div className={ countButtonsLeft.length > 5 ? "alt_width name_button border_left left_" + countLeft : "name_button border_left left_" + countLeft } key={ index } onClick={ ()=>{ setNamePage(key.name); setPrev(index - 1); setNext(index + 1) } }>
                                                        <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name ? key.name.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>

                                <div className={ countButtonsRight.length > 5 ? "div_buttons alt_gap" : "div_buttons" }>
                                    {
                                        showDataPage.button.map((key, index)=>{
                                            if(key.position == 'right'){
                                                countRight = countRight + 1;
                                                return(
                                                    <div className={ countButtonsRight.length > 5 ? "alt_width name_button border_right right_" + countRight : "name_button border_right right_" + countRight } key={ index } onClick={ ()=>{ setNamePage(key.name); setPrev(index - 1); setNext(index + 1) } }>
                                                        <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name ? key.name.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="div_img">
                                <img alt="img" src={ showDataPage.img } className="bg_img" />
                            </div>
                        </> :
                        ShowDetails()
                    }
                </div>
                
                <div className="div_example">
                    {
                        namePage == '' ? <img alt="img" src={ "./assets/cursor_" + idioma + ".png" } className="icone_button" /> : 
                        <>
                        
                            <div className="bg_data">
                                <img alt="img" src="./assets/Upgrades/bg_data.png" className="bg_data_img" />
                            </div>
                            {
                                prev == '-1' ?
                                <div className="return_stage stage_prev" onClick={ ()=>{ setNamePage(''); } }>
                                    <div className="return_arrow">
                                        <SvgArrow color="#FFDD00" />
                                    </div>
                                    <div className="return_name">
                                        { showDataPage.name }
                                    </div>
                                </div> : 
                                <div className="return_stage stage_prev" onClick={ ()=>{ setNamePage(showDataPage.button[prev].name); AltPrev(prev - 1, next - 1); } }>
                                    <div className="return_arrow">
                                        <SvgArrow color="#FFDD00" />
                                    </div>
                                    <div className="return_name">
                                        { showDataPage.button[prev].name }
                                    </div>
                                </div>
                            }
                            {
                                next >= showDataPage.button.length ? null :
                                <div className="return_stage stage_next" onClick={ ()=>{ setNamePage(showDataPage.button[next].name); AltPrev(prev + 1, next + 1); } }>
                                    <div className="return_name">
                                        { showDataPage.button[next].name }
                                    </div>
                                    <div className="return_arrow">
                                        <SvgArrow color="#FFDD00" className="arrow_right" />
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
                {
                    statusFile == false ? null :
                    <div className="div_parts">
                        <div className="list_div_parts">
                            <div className="close_modal" onClick={ ()=>{ setStatusFile(false); setOpenFile(''); setOpenIndex(''); } }>
                                <SvgClose className="icons" color="#FFDD00" />
                            </div>
                            {
                                (openIndex - 1) >= 0 ? 
                                <div className="prev_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenIndex(openIndex - 1) } }>
                                    <SvgArrowFile className="arrow_file" color="#FFDD00" />
                                </div> : null
                            }
                            {
                                (openIndex + 1) < showData.data[openFile].img.length ?
                                <div className="next_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenIndex(openIndex + 1) } }>
                                    <SvgArrowFile className="arrow_file next_icon" color="#FFDD00" />
                                </div> : null
                            }
                            <div className="show_details">
                                <div className="mach_div_img">
                                    <img alt="img" src={ showData.data[openFile].img[openIndex] } className="img_div_parts" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    function AltPrev(btn_prev, btn_next) {
        setPrev(btn_prev); 
        setNext(btn_next);

        if(document.querySelectorAll('.div_stamp')){
            const addClass_ = document.querySelectorAll('.div_stamp');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }
        if(document.querySelectorAll('.div_show_text')){
            const addClass_ = document.querySelectorAll('.div_show_text');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }
        if(document.querySelectorAll('.div_show_img')){
            const addClass_ = document.querySelectorAll('.div_show_img');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }

        setTimeout(() => {
            if(document.querySelectorAll('.div_stamp')){
                const addClass_ = document.querySelectorAll('.div_stamp');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.div_show_text')){
                const addClass_ = document.querySelectorAll('.div_show_text');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.div_show_img')){
                const addClass_ = document.querySelectorAll('.div_show_img');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
        }, 300);
    }

    function ShowDetails(){
        const showData = dataPage[page].button.find(item => item.name == namePage); 

        return(
            <>
                <div className="name_page_click">
                    <div className="show_name_page_click">
                        <div dangerouslySetInnerHTML={ { __html: namePage ? namePage.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        <div className={ showData.stamp_1 != '' && showData.stamp_2 != '' ? "stamp" : "stamp stamp_one" }>
                            {
                                showData.stamp_1 == '' ? null :
                                <div className="div_stamp">
                                    <img alt="img" src={ showData.stamp_1 } className="stamp_img" />
                                </div>
                            }
                            {
                                showData.stamp_2 == '' ? null :
                                <div className="div_stamp">
                                    <img alt="img" src={ showData.stamp_2 } className="stamp_img" />
                                </div>
                            }
                        </div>  
                    </div>  
                </div>
                <div className="show_data_details">
                    {
                        showData.data.map((key, index)=>{
                            switch (key.type) {
                                case "text":
                                    return(
                                        <div className="div_show_text" key={ index }>
                                            <div className="text" dangerouslySetInnerHTML={ { __html: key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                        </div>
                                    )
                                case "img":
                                    return(
                                        <div className={ 
                                            key.img.length > 1 ? 
                                                key.img.length == 2 ? "div_show_img div_double_img" : "div_show_img div_multiple_img" 
                                            : "div_show_img" } key={ index }>
                                            {
                                                key.img.map((key_1, index_1)=>{
                                                    countImg = countImg + 1
                                                    return(
                                                        <div className={ key.img.length > 1 ? "show_img show_img_" + countImg : "show_img show_multiple_img show_img_" + countImg } key={ index_1 } onClick={ ()=>{ setStatusFile(true); setOpenFile(index); setOpenIndex(index_1) } }>
                                                            <div className="search" style= { { right: "50px" } }>
                                                                <img alt="search" src="./assets/search.png" className="icon_search" />
                                                            </div> 
                                                            <img alt="img_" src={ key_1 } className={ key.img.length == 1 ?"img_ heightTotal" : "img_" } />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                            }
                        })
                    }
                </div>
            </>
        )
    }

    return(
        <div className="MesaUpgrades">
            {
                idioma === "" ? 
                <div className="div_idioma">
                    <div className="show_idioma" onClick={ ()=>{ setIdioma('pt_br') } }>Português</div>
                    <div className="show_idioma" onClick={ ()=>{ setIdioma('en') } }>Inglês</div>
                    <div className="show_idioma" onClick={ ()=>{ setIdioma('es') } }>Espanhol</div>
                </div> : 
                <>           
                    <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } />
                    { TypePage() }
                    <div className="div_bg">
                        <img alt="img" src="./assets/texture.png" className="texture" />
                    </div>
                </>
            }
        </div>
    )
}