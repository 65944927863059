import { useState, useEffect, useRef } from "react";

import './MesaOperationCenter.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgArrow, SvgArrowFile, SvgClose, SvgHome } from "components/Svg";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

export default function MesaOperationCenter(props){

    const [ showData, setShowData ] = useState(false);
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('operationCenter'));
    const [ page, setPage ]         = useState('index');
    const [ namePage, setNamePage ] = useState('');

    const [ next, setNext ] = useState('');
    const [ prev, setPrev ] = useState('');

    const [ status, setStatus ] = useState(false);
    
    const [ openIndex, setOpenIndex ]   = useState('');
    const [ openFile, setOpenFile ]     = useState('');
    const [ statusFile, setStatusFile ] = useState(false);

    let countLeft  = 0;
    let countRight = 0;
    let countImg   = 0;

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('operationCenter', setDataPage);
        TypePage();        
    }, []); 

    useEffect(()=>{
        TypePage();
    }, [page]);

    function TypePage(){
        switch (page) {
            case 'index':
                return (                    
                    <div className="div_data">
                        <div className="div_machine" onClick={ ()=>{ setPage('automation') } }>
                            <div className="show_title">
                                { dataPage.home['text_1'][idioma] }
                            </div>
                            <img alt="name" src={ "./assets/OperationCenter/machine_" + idioma + ".jpg" } className="img_mach" />
                        </div>
                        <div className="div_mandala">
                            <div className="circle_rotate">
                                <div className="div_click_button">
                                    <div className="btn_1" onClick={ ()=>{ setPage('preparo') } } />
                                    <div className="btn_2" onClick={ ()=>{ setPage('plantio') } } />
                                    <div className="btn_3" onClick={ ()=>{ setPage('colheita') } } />
                                    <div className="btn_4" onClick={ ()=>{ setPage('tratos_culturais') } } />
                                    <img alt="img" src={ "./assets/OperationCenter/btn_" + idioma + ".png" } className="" />
                                </div>
                                <div className="title_circle" onClick={ ()=>{ setPage('planejamento') } }>
                                    { dataPage.home['text_2'][idioma] }
                                </div>
                            </div>
                            <div className="div_example">
                                <img alt="img" src={ "./assets/cursor_" + idioma + ".png" } className="icone_button" />
                            </div>
                        </div>
                        <div className="div_parts">
                            <div className="div_side">
                                <img alt="name" src={ "./assets/OperationCenter/side_" + idioma + ".png" } className="img_side" />
                            </div>
                            <div className="list_part">
                                <div className="show_part" onClick={ ()=>{ setPage('monitor_G5') } }>
                                    <div className="show_title">
                                        MONITOR G5
                                    </div>
                                    <img alt="name" src="./assets/OperationCenter/parts_1.png" className="img_part" />
                                </div>
                                <div className="show_part" onClick={ ()=>{ setPage('starfire_7000') } }>
                                    <div className="show_title">
                                        STARFIRE 7000
                                    </div>
                                    <img alt="name" src="./assets/OperationCenter/parts_2.jpg" className="img_part" />
                                </div>
                                <div className="show_part" onClick={ ()=>{ setPage('jdlink_modem_m') } }>
                                    <div className="show_title">
                                        JDLink Modem M
                                    </div>
                                    <img alt="name" src="./assets/OperationCenter/parts_3.jpg" className="img_part" />
                                </div>
                                <div className="show_part" onClick={ ()=>{ setPage('jdlink_modem_r') } }>
                                    <div className="show_title">
                                        JDLink Modem R
                                    </div>
                                    <img alt="name" src="./assets/OperationCenter/parts_4.jpg" className="img_part" />
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case 'automation': case 'monitor_G5': case 'starfire_7000': case 'jdlink_modem_m': case 'jdlink_modem_r':
                return ListDataFixed();

            default:
                return ListDataClick();
        }
    }

    function PlayVideo(id_video, id_div, btn_video) {
        const play_ = document.getElementById(id_video);
        if (play_.paused){
            play_.play();
            document.getElementById(btn_video).style.opacity = "0";
            document.getElementById(id_div).style.opacity = "0";
        }else {
            play_.pause();
            document.getElementById(btn_video).style.opacity = "1";
            document.getElementById(id_div).style.opacity = "1";
        }
    }

    function ListDataFixed(){
        const showDataPage = dataPage[page];
        let showText = '';
        if(showDataPage.data.find(item => item.type == 'text')){
            showText = showDataPage.data.find(item => item.type == 'text');  
        }

        let countImg = 0;
        if(showDataPage.data.find(item => item.type == 'img')){
            let showCountVideo = showDataPage.data.filter(item => item.type == 'img');
            countImg = showCountVideo.length;
        }

        let countVideo = 0;
        if(showDataPage.data.find(item => item.type == 'video')){
            let showCountVideo = showDataPage.data.filter(item => item.type == 'video');
            countVideo = showCountVideo.length;
        }
        let total = countImg + countVideo;

        return(
            <div className="div_data data_fixed">
                <div className="topic_fixed">
                    <div className="div_logo" onClick={ ()=>{ setPage('index'); setStatus(false) } }>
                        <img alt="img" src="./assets/home.png" className="img_home" />
                    </div>
                    <div className="name_page">
                        <div className="subtitle">
                            { showDataPage.subtitle[idioma] }
                        </div>
                        <div className="title">
                            { showDataPage.title[idioma] }
                        </div>
                    </div>
                </div>
                <div className="div_contents">
                    {
                        showText.text[idioma] ? 
                        <div className="div_text" dangerouslySetInnerHTML={ { __html: showText.text[idioma] ? showText.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } /> : null
                    }

                    <div className={ showText.text[idioma] ? "mult_data" : "mult_data mult_data_full" }>
                        {
                            showDataPage.data.map((key, index)=>{
                                switch (key.type) {
                                    case 'img':
                                        return(
                                            <div className={ total == 2 ? "div_img div_two" : total == 1 ? "div_img div_one" : "div_img" } key={ index }>
                                                <img alt="img" src={ key.file } className="img_" />
                                            </div>
                                        )
                                    case 'video':
                                        return(
                                            <div className={ showText.text[idioma] ? total == 1 ?  "div_video video_one" : "div_video" : countVideo == 1 ? "div_video video_full" : "div_video mult_data_two_full" } key={ index }>
                                                <div className={ showText.text[idioma] ? "img_example" : "img_example img_example_full" }>
                                                    <div className="play" id={ "play_" + index } onClick={ ()=>{ PlayVideo('open_video_' + index + '', 'play_' + index + '', 'btn_video_' + index + ''); setStatus(!status) } }>
                                                        <div id={ "btn_video_" + index } className={ showText.text[idioma] ? "img_play" :  countVideo == 1 ? "img_play hide_play" : "img_play" }>
                                                            <img alt="img" src="./assets/OperationCenter/player.png" className="img_icon" />
                                                        </div> 
                                                        { 
                                                            showText.text[idioma] ? 
                                                            <>                                                       
                                                                <img alt="img" src={ key.file } className="img_" />
                                                            </> :  null
                                                        }
                                                    </div>
                                                    <div>  
                                                        {
                                                            showText.text[idioma] ? 
                                                            <video id={ "open_video_" + index } width="100%" height="100%" controls={ false } loop={ true }>
                                                                <source src={ key.video } type="video/mp4" />
                                                            </video> :
                                                            <>
                                                                {
                                                                    countVideo == 1 ?
                                                                    <video id={ "open_video_" + index } width="100%" height="100%" controls={ false } loop={ true } autoPlay="autoplay">
                                                                        <source src={ key.video } type="video/mp4" />
                                                                    </video> : 
                                                                    <video id={ "open_video_" + index } width="100%" height="100%" controls={ false } loop={ true }>
                                                                        <source src={ key.video } type="video/mp4" />
                                                                    </video>
                                                                }        
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    function ListDataClick(){
        const showDataPage = dataPage[page];        
        const countButtonsLeft  = showDataPage.button.filter(item =>item.position === 'left');
        const countButtonsRight = showDataPage.button.filter(item =>item.position === 'right');

        return(
            <div className="div_data list_details">
                <div className="div_topic topic_details">
                    <div className="return" onClick={ ()=>{ setPage('index'); setNamePage('') } }>
                        <SvgHome className="icon_home" color="#FFDD00" />
                    </div>
                    <div className="name_current_page">
                        <div className="show_name">/</div>
                        <div className="" onClick={ ()=>{ setPage('index'); setNamePage('') } }>Operations Center</div>
                        <div className="show_name">/</div>
                        <div className="" onClick={ ()=>{ setPage(page); setNamePage('') } }>{ showDataPage.name[idioma] }</div>
                        {
                            namePage == '' ? null : 
                            <>
                                <div className="show_name">/</div>
                                <div className="">{ namePage[idioma] }</div>
                            </>
                        }
                    </div>
                </div>

                <div className="div_list_data">
                    {
                        namePage == '' ?
                        <>                            
                            <div className="show_title">{ showDataPage.name[idioma] }</div>
                            <div className="show_buttons">

                                <div className={ countButtonsLeft.length > 5 ? "div_buttons alt_gap" : "div_buttons" }>
                                    {
                                        showDataPage.button.map((key, index)=>{
                                            if(key.position == 'left'){
                                                countLeft = countLeft + 1;
                                                return(
                                                    <div className={ countButtonsLeft.length > 5 ? "alt_width name_button border_left left_" + countLeft : "name_button border_left left_" + countLeft } key={ index } onClick={ ()=>{ setNamePage(key.name[idioma]); setPrev(index - 1); setNext(index + 1) } }>
                                                        <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name[idioma] ? key.name[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>

                                <div className={ countButtonsRight.length > 5 ? "div_buttons alt_gap" : "div_buttons" }>
                                    {
                                        showDataPage.button.map((key, index)=>{
                                            if(key.position == 'right'){
                                                countRight = countRight + 1;
                                                return(
                                                    <div className={ countButtonsRight.length > 5 ? "alt_width name_button border_right right_" + countRight : "name_button border_right right_" + countRight } key={ index } onClick={ ()=>{ setNamePage(key.name[idioma]); setPrev(index - 1); setNext(index + 1) } }>
                                                        <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name[idioma] ? key.name[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="div_img">
                                <img alt="img" src={ showDataPage.img } className="bg_img" />
                            </div>
                        </> :
                        ShowDetails()
                    }
                </div>
                
                <div className="div_example">
                    {
                        namePage == '' ? <img alt="img" src={ "./assets/cursor_" + idioma + ".png" } className="icone_button" /> : 
                        <>
                        
                            <div className="bg_data">
                                <img alt="img" src="./assets/Upgrades/bg_data.png" className="bg_data_img" />
                            </div>
                            {
                                prev == '-1' ?
                                <div className="return_stage stage_prev" onClick={ ()=>{ setNamePage(''); } }>
                                    <div className="return_arrow">
                                        <SvgArrow color="#FFDD00" />
                                    </div>
                                    <div className="return_name">
                                        { showDataPage.name[idioma] }
                                    </div>
                                </div> : 
                                <div className="return_stage stage_prev" onClick={ ()=>{ setNamePage(showDataPage.button[prev].name[idioma]); AltPrev(prev - 1, next - 1); } }>
                                    <div className="return_arrow">
                                        <SvgArrow color="#FFDD00" />
                                    </div>
                                    <div className="return_name">
                                        { showDataPage.button[prev].name[idioma] }
                                    </div>
                                </div>
                            }
                            {
                                next >= showDataPage.button.length ? null :
                                <div className="return_stage stage_next" onClick={ ()=>{ setNamePage(showDataPage.button[next].name[idioma]); AltPrev(prev + 1, next + 1); } }>
                                    <div className="return_name">
                                        { showDataPage.button[next].name[idioma] }
                                    </div>
                                    <div className="return_arrow">
                                        <SvgArrow color="#FFDD00" className="arrow_right" />
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        )
    }

    function AltPrev(btn_prev, btn_next) {
        setPrev(btn_prev); 
        setNext(btn_next);

        if(document.querySelectorAll('.div_stamp')){
            const addClass_ = document.querySelectorAll('.div_stamp');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }
        if(document.querySelectorAll('.div_text')){
            const addClass_ = document.querySelectorAll('.div_text');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }
        if(document.querySelectorAll('.mult_data')){
            const addClass_ = document.querySelectorAll('.mult_data');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }

        setTimeout(() => {
            if(document.querySelectorAll('.div_stamp')){
                const addClass_ = document.querySelectorAll('.div_stamp');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.div_text')){
                const addClass_ = document.querySelectorAll('.div_text');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.mult_data')){
                const addClass_ = document.querySelectorAll('.mult_data');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
        }, 300);
    }

    function ShowDetails(){
        const showData = dataPage[page].button.find(item => item.name[idioma] == namePage); 

        let showText = '';
        if(showData.data.find(item => item.type == 'text')){
            showText = showData.data.find(item => item.type == 'text');  
        }

        let countImg = 0;
        if(showData.data.find(item => item.type == 'img')){
            let showCountVideo = showData.data.filter(item => item.type == 'img');
            countImg = showCountVideo.length;
        }

        let countVideo = 0;
        if(showData.data.find(item => item.type == 'video')){
            let showCountVideo = showData.data.filter(item => item.type == 'video');
            countVideo = showCountVideo.length;
        }
        let total = countImg + countVideo;

        return(
            <>
                <div className="name_page_click" id="show_data_page">
                    <div className="show_name_page_click">
                        <div>{ namePage }</div>
                        {/* <div className={ showData.stamp_1 != '' && showData.stamp_2 != '' ? "stamp" : "stamp stamp_one" }>
                            {
                                showData.stamp_1 == '' ? null :
                                <div className="div_stamp">
                                    <img alt="img" src={ showData.stamp_1 } className="stamp_img" />
                                </div>
                            }
                            {
                                showData.stamp_2 == '' ? null :
                                <div className="div_stamp">
                                    <img alt="img" src={ showData.stamp_2 } className="stamp_img" />
                                </div>
                            }
                        </div>   */}
                    </div>  
                </div>
                <div className="div_contents">
                    {
                        showText.text[idioma] ? 
                        <div className="div_text" dangerouslySetInnerHTML={ { __html: showText.text[idioma] ? showText.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } /> : null
                    }

                    <div className={ showText.text[idioma] ? "mult_data" : "mult_data mult_data_full" }>
                        {
                            showData.data.map((key, index)=>{
                                switch (key.type) {
                                    case 'img':
                                        return(
                                            <div className={ total == 2 ? "div_img div_two" : total == 1 ? "div_img div_one" : "div_img" } key={ index } onClick={ ()=>{ setStatusFile(true); setOpenFile(index); } }>  
                                                <div className="search">
                                                    <img alt="search" src="./assets/search.png" className="icon_search" />
                                                </div> 
                                                <img alt="img" src={ key.file } className="img_" />
                                            </div>
                                        )
                                    case 'video':
                                        return(
                                            <div className={ showText.text[idioma] ? total == 1 ?  "div_video video_one" : "div_video" : "div_video video_full" } key={ index }>
                                                <div className={ showText.text[idioma] ? "img_example" : "img_example img_example_full" }>
                                                    <div id={ "play_" + index } className="play" onClick={ ()=>{ PlayVideo('open_video_' + index + '', 'play_' + index + '', 'btn_video_' + index + ''); setStatus(!status) } }>
                                                        <div id={ "btn_video_" + index } className={ showText.text[idioma] ? "img_play" : "img_play hide_play" }>
                                                            <img alt="img" src="./assets/OperationCenter/player.png" className="img_icon" />
                                                        </div> 
                                                        { 
                                                            showText.text[idioma] ? 
                                                            <>                                                       
                                                                <img alt="img" src={ key.file } className="img_" />
                                                            </> : null
                                                        }
                                                    </div>
                                                    <div>  
                                                        {
                                                            showText.text[idioma] ? 
                                                            <video id={ "open_video_" + index } width="100%" height="100%" controls={ false } loop={ true }>
                                                                <source src={ key.video } type="video/mp4" />
                                                            </video> :
                                                            <video id={ "open_video_" + index } width="100%" height="100%" controls={ false } loop={ true } autoPlay="autoplay">
                                                                <source src={ key.video } type="video/mp4" />
                                                            </video>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                }
                            })
                        }
                    </div>
                </div>
                
                {
                    statusFile == false ? null :
                    <div className="div_modal">
                        <div className="list_div_modal">
                            <div className="close_modal" onClick={ ()=>{ setStatusFile(false); setOpenFile(''); setOpenIndex(''); } }>
                                <SvgClose className="icons" color="#FFDD00" />
                            </div>
                            {
                                openFile > 1 ? 
                                <div className="prev_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenFile(openFile - 1) } }>
                                    <SvgArrowFile className="arrow_file" color="#FFDD00" />
                                </div> : null
                            }
                            {
                                openFile < countImg ?
                                <div className="next_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenFile(openFile + 1) } }>
                                    <SvgArrowFile className="arrow_file next_icon" color="#FFDD00" />
                                </div> : null
                            }
                            <div className="show_details">
                                <div className="mach_div_img">
                                    <img alt="img" src={ showData.data[openFile].file } className="img_div_modal" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

    return(
        <div className="MesaOperationCenter">
            {
                idioma === "" ? 
                <div className="div_idioma">
                    <div className="show_idioma" onClick={ ()=>{ setIdioma('pt_br') } }>Português</div>
                    <div className="show_idioma" onClick={ ()=>{ setIdioma('en') } }>Inglês</div>
                    <div className="show_idioma" onClick={ ()=>{ setIdioma('es') } }>Espanhol</div>
                </div> : 
                <>                
                    {
                        page == 'index' ? 
                        <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } style={ { right: 'auto' } } /> :
                        <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } />
                    }
                    { TypePage() }
                    <div className="div_bg">
                        <img alt="img" src="./assets/texture.png" className="texture" />
                    </div>
                </>
            }
        </div>
    )
}