import { useState, useEffect } from "react";

import './WhereToBuy.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function MesaUnimil_WhereToBuy(props){
    
    const [ dataPage, setDataPage ]         = useState(GetDataPage('unimil'));

    const [ title, setTitle ]       = useState('');
    const [ subtitle, setSubtitle ] = useState('');
    const [ text, setText ]         = useState('');
    const [ img, setImg ]           = useState([]);

    useEffect(()=>{
        RegisterDataPage('unimil', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('unimil'));

        InitialData('subtitle');
        InitialData('button');
        InitialData('text');
        InitialData('img');
    }, [dataPage]);

    function InitialData(type){
        setTimeout(() => {
            switch (type) {
                case 'subtitle':
                        setTitle(GetDataPage('unimil').about['subtitle_3']);
                    break;

                case 'button':
                        setSubtitle(GetDataPage('unimil').about['button_3']);
                    break;
                    
                case 'text':
                        setText(GetDataPage('unimil').about['text_3']);
                    break;
                    
                case 'img':
                        setImg(GetDataPage('unimil').about['img_3']);
                    break;
            }
        }, 1000); 
    }
    
    return(
        <div className="MesaUnimil_WhereToBuy">
            <div className="div_data">
                <div className="div_topic">
                    <div className="div_home" onClick={ ()=>{ props.ClickPage('index'); } }>
                        <img alt="img" src="./assets/home.png" className="img_home" />
                    </div>
                    <div className="div_name_page">
                        <div className="subtitle_page">{ title }</div>
                        <div className="name_page">{ subtitle }</div>
                    </div>
                    <div className="div_logotipo">
                        <img alt="img" src="./assets/Unimil/logo.png" className="logotipo" />
                    </div>
                </div>
                <div className="contents">
                    <div className="text" dangerouslySetInnerHTML={ { __html: text ? text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                    <div className="img">
                        {
                            img.map((key, index)=>{
                                return(
                                    <div className="show_img" key={ index }>
                                        <img alt="img" src={ key } className="img" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="div_bg_color" />
            </div>
        </div>
    )
}