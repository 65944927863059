import { useState, useEffect } from "react";

import './MesaPartsAndServices.css';

import MesaPartsAndServices_Agricultural from "./Agricultural";
import MesaPartsAndServices_Construction from "./Construction";
import { GetDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function MesaPartsAndServices(props){

    const nameUrl                   = window.location.href.split("#");
    const [ showData, setShowData ] = useState(false);
    const [ page, setPage ]         = useState('agricultural_index');
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('partsAndServices').home);

    useEffect(()=>{
        CountShowData();
        TypePage();

        RegisterListPag('idioma', setIdioma);
    }, []); 

    useEffect(()=>{
        CountShowData();
        TypePage();
    }, [page]);
    
    function CountShowData(){
        let count = 0;
        const countData = setInterval(() => {
            if(count == 1){
                setShowData(true); 
                clearInterval(countData);
            }
            count++; 
        }, 300);
    }
    
    function TypePage(){
        switch (page) {
            case 'index':
                return (                    
                    <div className={ showData == true ? "div_data show_data" : "div_data" }>
                        <div className="title">
                            <img alt="name" src={ "./assets/PartsAndServices/name_" + idioma + ".svg" } className="svg_name" />
                        </div>
                        <div className="div_button">
                            <div className="button btn_1">
                                <div className="show_name button_1" onClick={ ()=>{ setPage('agricultural_index') } }>
                                    { dataPage.text_1[idioma] }
                                </div>
                            </div>
                            <div className="button btn_2">
                                <div className="show_name button_2" onClick={ ()=>{ setPage('construction') } }>
                                    { dataPage.text_2[idioma] }
                                </div>
                            </div>
                        </div>
                        <div className="div_example">
                            <img alt="img" src={ "./assets/cursor_" + idioma + ".png" } className="icone_button" />
                        </div>
                        <div className="div_img">
                            <img alt="img" src="./assets/PartsAndServices/bg_page.jpg" className="bg_img" />
                        </div>
                    </div>
                );
            
            case 'agricultural_index':
                // nameClick={ dataPage.text_1[idioma] }
                return <MesaPartsAndServices_Agricultural ClickPage={ ClickPage } idioma={ idioma } showDataPage={ GetDataPage('partsAndServices') } />;
            
            case 'construction':
                // nameClick={ dataPage.text_2[idioma] } 
                return <MesaPartsAndServices_Construction ClickPage={ ClickPage } idioma={ idioma } />;
        }
    }

    function ClickPage(value){
        setPage(value);
    }

    return(
        <div className="MesaPartsAndServices">
            {
                idioma === "" ? 
                <div className="div_idioma">
                    <div className="show_idioma" onClick={ ()=>{ setIdioma('pt_br') } }>Português</div>
                    <div className="show_idioma" onClick={ ()=>{ setIdioma('en') } }>Inglês</div>
                    <div className="show_idioma" onClick={ ()=>{ setIdioma('es') } }>Espanhol</div>
                </div> : 
                <>      
                    <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } />
                    { TypePage() }
                    <div className="div_bg">
                        <img alt="img" src="./assets/texture.png" className="texture" />
                    </div>
                </>
            }            
        </div>
    )
}