import { useState, useEffect } from "react";

import './Agricultural.css';

import { SvgArrow, SvgArrowFile, SvgClose, SvgHome } from "components/Svg";

import { GetDataPage } from "interface/Data";

export default function MesaPartsAndServices_Agricultural(props){

    const [ currentPage, setCurrentPage ] = useState('agricultural_index');
    const [ pageDetails, setPageDetails ] = useState('list_details');
    const [ dataPage, setDataPage ]       = useState(GetDataPage('partsAndServices').agricultural);

    const [ pagePul, setPagePul ] = useState('');

    const [ next, setNext ] = useState('');
    const [ prev, setPrev ] = useState('');

    const [ status, setStatus ] = useState(false);
    
    const [ openIndex, setOpenIndex ]   = useState('');
    const [ openFile, setOpenFile ]     = useState('');
    const [ statusFile, setStatusFile ] = useState(false);

    let countLeft  = 0;
    let countRight = 0;
    let countImg   = 0;

    useEffect(()=>{
        ShowDataPage();
    }, []);

    useEffect(()=>{
        ShowDataPage();
    }, [currentPage]);

    useEffect(()=>{
        ShowDataPage();
    }, [pageDetails]);

    function ShowDataPage(){
        switch (currentPage) {
            case 'agricultural_index':
                return(
                    <>
                        <div className="div_topic">
                            {/* <div className="icon_return">
                                <div className="return" onClick={ ()=>{ props.ClickPage('agricultural_index') } }>
                                    <SvgHome className="icon_home" color="#FFDD00" />
                                </div>
                                <div className="button">
                                    <div className="show_name">{ props.nameClick }</div>
                                </div>
                            </div> */}
                            <div className="title_">
                                <img alt="name" src={ "./assets/PartsAndServices/name_" + props.idioma + ".svg" } className="svg_name" />
                            </div>
                        </div>
                        <div className="circle_rotate">
                            <div className="div_click_button">
                                <div className="btn_1" onClick={ ()=>{ setCurrentPage('preparo'); } } />
                                <div className="btn_2" onClick={ ()=>{ setCurrentPage('plantio') } } />
                                <div className="btn_3" onClick={ ()=>{ setStatus(true) } } />
                                <div className="btn_4" onClick={ ()=>{ setCurrentPage('tratos_culturais') } } />
                                <img alt="img" src={ "./assets/PartsAndServices/btn_" + props.idioma + ".png" } className="" />
                            </div>
                            <div className="title_circle" onClick={ ()=>{ setCurrentPage('planning_construction') } }>
                                { dataPage.text_1[props.idioma] }
                            </div>
                        </div>
                        {
                            status == false ? null :
                            <div className="div_machine">
                                <div className="list_machine">
                                    <div className="close_modal" onClick={ ()=>{ setStatus(false); } }>
                                        <SvgClose className="icons" color="#387C2B" />
                                    </div>
                                    <div className="show_details" onClick={ ()=>{ setCurrentPage('colheita_algodao'); setStatus(false) } }>
                                        <div className="mach_subtitle">{ dataPage.text_2[props.idioma] }</div>
                                        <div className="mach_title">{ dataPage.text_3[props.idioma] }</div>
                                        <div className="mach_div_img">
                                            <img alt="img" src="./assets/PartsAndServices/colhe_1.jpg" className="img_machine" />
                                        </div>
                                    </div>
                                    <div className="show_details" onClick={ ()=>{ setCurrentPage('colheita_graos'); setStatus(false) } }>
                                        <div className="mach_subtitle">{ dataPage.text_2[props.idioma] }</div>
                                        <div className="mach_title">{ dataPage.text_4[props.idioma] }</div>
                                        <div className="mach_div_img">
                                            <img alt="img" src="./assets/PartsAndServices/colhe_2.jpg" className="img_machine" />
                                        </div>
                                    </div>
                                    <div className="show_details" onClick={ ()=>{ setCurrentPage('colheita_cana'); setStatus(false) } }>
                                        <div className="mach_subtitle">{ dataPage.text_2[props.idioma] }</div>
                                        <div className="mach_title">{ dataPage.text_5[props.idioma] }</div>
                                        <div className="mach_div_img">
                                            <img alt="img" src="./assets/PartsAndServices/colhe_3.jpg" className="img_machine" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                );
            
            default:
                return ListDataClick();
        }
    }

    function ListDataClick(){
        return(
            <>
                <div className={ pagePul == '' ? "div_topic" : "div_topic div_topic_pul" }>
                    <div className="icon_return">
                        <div className="return" onClick={ ()=>{ props.ClickPage('agricultural_index'); setCurrentPage('agricultural_index'); setPageDetails('list_details') } }>
                            <SvgHome className="icon_home" color="#FFDD00" />
                        </div>
                        <div className="name_current_page">
                            <div className="show_name">/</div>
                            {
                                pageDetails == 'list_details' ? 
                                <>
                                    <div className="show_name" onClick={ ()=>{ setCurrentPage('agricultural_index'); setPagePul(''); } }>
                                        Agrícola
                                    </div>
                                    <div className="show_name">/</div>
                                    <div className="show_name">{ dataPage.text_6[props.idioma] }</div>
                                </> :
                                <>        
                                    {
                                        pageDetails === 'Pulverização' ? 
                                        <>                    
                                            <div className="show_name" onClick={ ()=>{ setCurrentPage(currentPage); setPageDetails('list_details'); setPagePul(''); } }>
                                                Agrícola
                                            </div>
                                            <div className="show_name" onClick={ ()=>{ setCurrentPage(currentPage); setPageDetails('list_details'); setPagePul(''); } }>/</div>
                                            <div className="show_name" onClick={ ()=>{ setCurrentPage('tratos_culturais'); setPageDetails('list_details'); setPagePul(''); } }>Pulverização</div>
                                            {
                                                pagePul == '' ? null :
                                                <>
                                                    <div className="show_name">/</div>
                                                    <div className="show_name" onClick={ ()=>{ setCurrentPage('Pulverização'); setPagePul(''); } }>Pulverização</div>
                                                </>
                                            }
                                        </> :
                                        <>                        
                                            <div className="show_name" onClick={ ()=>{ setCurrentPage('agricultural_index'); setPageDetails('list_details') } }>
                                                { dataPage.text_6[props.idioma] }
                                            </div>
                                            <div className="show_name">/</div>
                                            <div className="show_name" onClick={ ()=>{ setCurrentPage(currentPage); setPageDetails('list_details') } }>Pulverização</div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                { ShowDetailsClick() }
            </>
        )
    }

    function ShowDetailsClick(){
        switch (pageDetails) {
            case 'list_details':
                let showDataClick = dataPage[currentPage];
                const countButtonsLeft  = showDataClick.button.filter(item =>item.position === 'left');
                const countButtonsRight = showDataClick.button.filter(item =>item.position === 'right');
                
                return(
                    <>
                        <div className="div_name_page">
                            <div className="name_page">{ showDataClick.name }</div>
                        </div>
                        <div className="show_buttons">
                            <div className={ countButtonsLeft.length > 5 ? "div_buttons alt_gap" : "div_buttons" }>
                                {
                                    showDataClick.button.map((key, index)=>{
                                        if(key.position == 'left'){
                                            countLeft = countLeft + 1;
                                            return(
                                                <div className={ countButtonsLeft.length > 5 ? "alt_width name_button border_left left_" + countLeft : "name_button border_left left_" + countLeft } key={ index } onClick={ ()=>{ setPageDetails(key.name); setPrev(index - 1); setNext(index + 1) } }>
                                                    <span className="space_name">{ key.name }</span>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <div className={ countButtonsRight.length > 5 ? "div_buttons alt_gap" : "div_buttons" }>
                                {
                                    showDataClick.button.map((key, index)=>{
                                        if(key.position == 'right'){
                                            countRight = countRight + 1;
                                            return(
                                                <div className={ countButtonsRight.length > 5 ? "alt_width name_button border_right right_" + countRight : "name_button border_right right_" + countRight } key={ index } onClick={ ()=>{ setPageDetails(key.name); setPrev(index - 1); setNext(index + 1) } }>
                                                    <span className="space_name">{ key.name }</span>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                {
                                    currentPage == 'tratos_culturais' ? 
                                    <div className={ countButtonsRight.length > 5 ? "alt_width name_button border_right right_" + countRight : "name_button border_right right_" + countRight } onClick={ ()=>{ setPageDetails('Pulverização'); } }>
                                        <span className="space_name">{ dataPage['pulverizacao'].name }</span>
                                    </div> : null
                                }
                            </div>
                        </div>
                        <div className="div_img">
                            <img alt="img" src={ showDataClick.img } className="bg_img" />
                        </div>
                    </>
                );
            
            default:
                if(pageDetails === 'Pulverização'){
                    const showData = dataPage['pulverizacao'];
                    let countLeftPul = 0;
                    let countRightPul = 0;
                    return(
                        <>
                            {
                                pagePul != '' ? ShowDataPulverization() :
                                <>
                                    <div className="div_name_page">
                                        <div className="name_page">{ dataPage['pulverizacao'].name }</div>
                                    </div>
                                    <div className="show_buttons">
                                        <div className="btn_pul_left">
                                            {
                                                showData.button.map((key, index)=>{
                                                    if(key.position == 'left'){
                                                        countLeftPul = countLeftPul + 1;
                                                        return(
                                                            <div className={ "show_btn_left pul_left_" + countLeftPul } key={ index } onClick={ ()=>{ setPagePul({ "position": "left", "id": key.id }); } }>
                                                                <div className="space_name">{ key.name }</div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        <div className="btn_pul_right">
                                            {
                                                showData.button.map((key, index)=>{
                                                    if(key.position == 'right'){
                                                        countRightPul = countRightPul + 1;
                                                        return(
                                                            <div className={ "show_btn_right pul_right_" + countRightPul } key={ index } onClick={ ()=>{ setPagePul({ "position": "right", "id": key.id }); } }>
                                                                <div className="space_name">
                                                                    
                                                                    <div className="div_icon_btn_">
                                                                        <img alt="img" src={ key.icon } className="icon_btn_" />
                                                                    </div>
                                                                    <div className="">
                                                                        { key.name }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    key.highlight == "Sim" ?
                                                                    <div className="highlight">
                                                                        <div>ideal para exactapply</div>
                                                                        <div className="highlight_circle" />
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="div_img_pulv">
                                        <img alt="img" src={ showData.img } className="bg_img" />
                                    </div>
                                </>
                            }
                        </>
                    ) 
                    
                }else {
                    const showData = dataPage[currentPage].button.find(item => item.name == pageDetails); 
                    
                    return(
                        <>
                            <div className="name_page_click">
                                <div className="show_name_page_click">
                                    <div>{ pageDetails }</div>
                                    <div className={ showData.stamp_1 != '' && showData.stamp_2 != '' ? "stamp" : "stamp stamp_one" }>
                                        {
                                            showData.stamp_1 == '' ? null :
                                            <div className="div_stamp">
                                                <img alt="img" src={ showData.stamp_1 } className="stamp_img" />
                                            </div>
                                        }
                                        {
                                            showData.stamp_2 == '' ? null :
                                            <div className="div_stamp">
                                                <img alt="img" src={ showData.stamp_2 } className="stamp_img" />
                                            </div>
                                        }
                                    </div>  
                                </div>                          
                            </div>
                            { ShowDataPageClick() }
                            <div className="bg_data">
                                <img alt="img" src="./assets/PartsAndServices/bg_data_agricultural.png" className="bg_data_img" />
                            </div>
                            <div className="div_return">                            
                                {
                                    prev == '-1' ?
                                    <div className="return_stage stage_prev" onClick={ ()=>{ setCurrentPage(currentPage); setPageDetails('list_details') } }>
                                        <div className="return_arrow">
                                            <SvgArrow color="#FFDD00" />
                                        </div>
                                        <div className="return_name">
                                            { dataPage[currentPage].name }
                                        </div>
                                    </div> : 
                                    <div className="return_stage stage_prev" onClick={ ()=>{ AltPrev(prev - 1, next - 1); setPageDetails(dataPage[currentPage].button[prev].name) } }>
                                        <div className="return_arrow">
                                            <SvgArrow color="#FFDD00" />
                                        </div>
                                        <div className="return_name">
                                            { dataPage[currentPage].button[prev].name }
                                        </div>
                                    </div>
                                }
                                {
                                    next >= dataPage[currentPage].button.length ? null :
                                    <div className="return_stage stage_next" onClick={ ()=>{ AltPrev(prev + 1, next + 1); setPageDetails(dataPage[currentPage].button[next].name) } }>
                                        <div className="return_name">
                                            { dataPage[currentPage].button[next].name }
                                        </div>
                                        <div className="return_arrow">
                                            <SvgArrow color="#FFDD00" className="arrow_right" />
                                        </div>
                                    </div>
                                }
                            </div>                        
                            {
                                statusFile == false ? null :
                                <div className="div_parts">
                                    <div className="list_div_parts">
                                        <div className="close_modal" onClick={ ()=>{ setStatusFile(false); setOpenFile(''); setOpenIndex(''); } }>
                                            <SvgClose className="icons" color="#FFDD00" />
                                        </div>
                                        {
                                            (openIndex - 1) >= 0 ? 
                                            <div className="prev_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenIndex(openIndex - 1) } }>
                                                <SvgArrowFile className="arrow_file" color="#FFDD00" />
                                            </div> : null
                                        }
                                        {
                                            (openIndex + 1) < showData.data[openFile].img.length ?
                                            <div className="next_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenIndex(openIndex + 1) } }>
                                                <SvgArrowFile className="arrow_file next_icon" color="#FFDD00" />
                                            </div> : null
                                        }
                                        <div className="show_details">
                                            <div className="mach_div_img">
                                                <img alt="img" src={ showData.data[openFile].img[openIndex] } className="img_div_parts" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    )
                }
        }
    }

    function AltPrev(btn_prev, btn_next) {
        setPrev(btn_prev); 
        setNext(btn_next);

        if(document.querySelectorAll('.div_stamp')){
            const addClass_ = document.querySelectorAll('.div_stamp');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }

        if(document.querySelectorAll('.div_show_details_text')){
            const addClass_ = document.querySelectorAll('.div_show_details_text');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }        
        if(document.querySelectorAll('.div_show_details_contents')){
            const addClass_ = document.querySelectorAll('.div_show_details_contents');
            for (const addClass_alt of addClass_) {
                addClass_alt.style.display = 'none';
            }
        }        
        if(document.querySelectorAll('.show_img_1')){
            const addClass_ = document.querySelectorAll('.show_img_1');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }        
        if(document.querySelectorAll('.show_img_2')){
            const addClass_ = document.querySelectorAll('.show_img_2');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }        
        if(document.querySelectorAll('.show_img_3')){
            const addClass_ = document.querySelectorAll('.show_img_3');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }

        setTimeout(() => {
            if(document.querySelectorAll('.div_stamp')){
                const addClass_ = document.querySelectorAll('.div_stamp');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.div_show_details_text')){
                const addClass_ = document.querySelectorAll('.div_show_details_text');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }                
            if(document.querySelectorAll('.div_show_details_contents')){
                const addClass_ = document.querySelectorAll('.div_show_details_contents');
                for (const addClass_alt of addClass_) {
                    addClass_alt.style.display = 'flex';
                }
            }

            if(document.querySelectorAll('.show_img_1')){
                const addClass_ = document.querySelectorAll('.show_img_1');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.show_img_2')){
                const addClass_ = document.querySelectorAll('.show_img_2');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.show_img_3')){
                const addClass_ = document.querySelectorAll('.show_img_3');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
        }, 300);
    }

    function ShowDataPulverization(){
        const showData = dataPage['pulverizacao'].button.find(item => item.id == pagePul.id);
        const showImg   = showData.data.find(item =>item.type == 'img');
        const showVideo = showData.data.filter(item =>item.type == 'video');
        const showText  = showData.data.find(item =>item.type == 'text');

        if(pagePul.position == 'left'){
            return(
                <div className="pulv_data_left">
                    <div className="btn_">
                        <div className="title_fixed">
                            { showData.title }
                        </div>
                    </div>
                    <div className="show_file_example">
                        <img alt="img" src={ showData.data[0].file } className="img_example_pul" />
                    </div>
                </div>
            )
        }else if(pagePul.position == 'right'){
            return(
                <div className="pulv_data_right">
                    <div className="btn_">
                        <div className="title_fixed">
                            <div className="btn_subtitle">
                                { showData.subtitle }
                            </div>
                            <div className="btn_title">
                                { showData.title }
                            </div>
                        </div>
                    </div>
                    <div className="pul_contents">
                        <div className="list_video">
                            {
                                showVideo.map((key, index)=>{
                                    return(
                                        <div className={ showVideo.length == 1 ? "div_video_full" : "div_video" } key={ index }>
                                            <video width="100%" height="100%" controls={ false } loop={ true } autoPlay="autoplay" >
                                                <source src={ key.file } type="video/mp4" />
                                            </video>
                                            <div className="name_video">{ key.title }</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="list_text">
                            <div className="div_text" dangerouslySetInnerHTML={ { __html: showText.text ? showText.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        </div>
                    </div>
                    <div className="img_fixed_right">
                        <img alt="img" src={ showImg.file } className="img_example_pul" />
                    </div>
                </div>
            )
        }
    }

    function PlayVideo(id_video, id_div, btn_video) {
        const play_ = document.getElementById(id_video);
        if (play_.paused){
            play_.play();
            document.getElementById(btn_video).style.opacity = "0";
            document.getElementById(id_div).style.opacity = "0";
        }else {
            play_.pause();
            document.getElementById(btn_video).style.opacity = "1";
            document.getElementById(id_div).style.opacity = "1";
        }
    }

    function ShowDataPageClick(){
        const newData = dataPage[currentPage].button.find(item => item.name == pageDetails);
        let countDivImg = 0;

        let showText = '';
        if(newData.data.find(item => item.type == 'text')){
            showText = newData.data.find(item => item.type == 'text');  
        }

        let countImg = 0;
        if(newData.data.find(item => item.type == 'img')){
            let showCountImg = newData.data.filter(item => item.type == 'img');
            countImg = showCountImg[0].img.length;
        }

        let countVideo = 0;
        if(newData.data.find(item => item.type == 'video')){
            let showCountVideo = newData.data.filter(item => item.type == 'video');
            countVideo = showCountVideo.length;
        }
        let total = countImg + countVideo;

        return(
            <div className="div_show_details">
                <div className="div_show_details_text">
                    <div className="div_show_text" dangerouslySetInnerHTML={ { __html: showText.text ? showText.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                </div>
                <div className="div_show_details_contents">
                    {
                        newData.data.map((key, index)=>{
                            switch (key.type) {
                                case 'video':
                                    return(
                                        <div className="div_show_details_div_video" key={ index }>
                                            <div id={ "play_" + index } className="play_video" onClick={ ()=>{ PlayVideo('open_video_' + index + '', 'play_' + index + '', 'btn_video_' + index + ''); setStatus(!status) } }>
                                                <div id={ "btn_video_" + index } className="div_show_details_div_video_img_play">
                                                    <img alt="img" src="./assets/PartsAndServices/player.png" className="img_icon" />
                                                </div> 
                                                <img alt="img" src={ key.video_img } className="div_show_details_div_video_img_" />
                                            </div>
                                            <div>  
                                                <video id={ "open_video_" + index } width="100%" height="100%" controls={ false } loop={ true }>
                                                    <source src={ key.video } type="video/mp4" />
                                                </video>
                                            </div>
                                        </div>
                                    )

                                case "img":
                                    return(
                                        <div className={ countVideo == 0 ? 
                                            total > 2 ? "div_show_details_div_img div_show_details_not_video" : "div_show_details_div_img" : "div_show_details_div_img div_mult_video" } key={ index }>
                                            {
                                                key.img.map((key_1, index_1)=>{
                                                    countDivImg = countDivImg + 1
                                                    return(
                                                        <div className={ 
                                                            total == 1 ? "div_show_details_show_img show_img_" + countDivImg : 
                                                            total == 2 ? "div_show_details_show_img two_show_img show_img_" + countDivImg : 
                                                            countVideo == 0 ? "div_show_details_show_img not_video_mult_show_img show_img_" + countDivImg : 
                                                            countImg > 2 ? "div_show_details_show_img mult_show_img_video show_img_" + countDivImg : "div_show_details_show_img mult_show_img show_img_" + countDivImg  } key={ index_1 }onClick={ ()=>{ setStatusFile(true); setOpenFile(index); setOpenIndex(index_1) } }>
                                                            <div className="search">
                                                                <img alt="search" src="./assets/search.png" className="icon_search" />
                                                            </div> 
                                                            <img alt="img_" src={ key_1 } className="div_show_details_img" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                            }
                        })
                    }
                </div>
            </div>
        )
    }

    return(
        <div className="MesaPartsAndServices_Agricultural">
            { ShowDataPage() }
            {
                pageDetails != 'list_details' ? null : 
                <div className="div_example">
                    <img alt="img" src={ "./assets/cursor_" + props.idioma + ".png" } className="icone_button" />
                </div>
            }
        </div>
    )
}